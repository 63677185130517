import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import tw from 'twin.macro'
import ContactForm from '../../components/ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import contentfulRichText from '../../lib/contentfulRichText'

const Contact: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      title1: contentfulTextCopy(key: { eq: "Have a Question?" }) {
        value
      }
      title2: contentfulTextCopy(key: { eq: "We're Here to help" }) {
        value
      }
      blurb: contentfulWysiwyg(
        contentTitle: { eq: "Contact Blurb" }
      ) {
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              fluid(maxWidth: 2500, quality: 60) {
                aspectRatio
                src
                srcSet
                sizes
              }
              fixed(width: 1600) {
                width
                height
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `)

  let blurb = null
  if (data.blurb) {
    blurb = contentfulRichText(JSON.parse(data.blurb.content.raw), data.blurb.content.references)
  }

  return (
    <Container id="contact" aria-labelledby="contact-heading">
      <InnerContainer>
        <LeftSide>
          <LeftSideInner className="max-w-lg mx-auto">
            <LeftSideTitle id="contact-heading">
              {data.title1.value} <Highlight>{data.title2.value}</Highlight>
            </LeftSideTitle>
            <LeftSideBlurb>
              {blurb}
            </LeftSideBlurb>
            <LeftSideInfo>
              <Phone>
                <StyledFontAwesome icon={['fas', 'phone']} size="2x" />
                <a href={`tel:+1${props.phone.replace(/\\D/g, '')}`}>{props.phone}</a>
              </Phone>
              <Address>
                <StyledFontAwesome icon={['fas', 'map-marker-alt']} size="2x" />
                <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props.address)}`}>{props.address}</a>
              </Address>
            </LeftSideInfo>
          </LeftSideInner>
        </LeftSide>
        <RightSide>
          <ContactForm />
        </RightSide>
      </InnerContainer>
    </Container>
  )
}

const Container = tw.div`relative bg-gradient-to-tl from-blue-300 to-gray-700`
const InnerContainer = tw.div`relative container mx-auto lg:grid lg:grid-cols-3`
const LeftSide = tw.div`bg-white py-16 px-6 lg:col-span-1 lg:px-8 lg:py-24 xl:pr-12`
const LeftSideInner = tw.div`max-w-lg mx-auto`
const LeftSideTitle = tw.h2`text-shadow-md font-heading text-2xl leading-8 text-gray-900 sm:text-3xl sm:leading-9`
const Highlight = tw.span`block text-blue-400`
const LeftSideBlurb = tw.p`mt-3 text-lg leading-6 text-gray-800`
const LeftSideInfo = tw.div`mt-8 text-base leading-6 text-gray-800`
const StyledFontAwesome = tw(FontAwesomeIcon)`h-6 w-6 mr-2 text-blue-600`
const Phone = tw.p`mt-6`
const Address = tw.p`mt-6`
const RightSide = tw.div`py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12`

interface Props {
  address: string;
  phone: string;
}

export default Contact

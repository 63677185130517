import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Modal: React.FC<ModalProps> = (props) => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(props.open)
    }, 1)
  }, [props.open])

  return (
    <OuterContainer open={open} mobileOnly={props.mobileOnly}>
      <Container open={open}>
        {props.withExitButton ? (
          <ExitButton onClick={props.onExitPress} icon={faTimes} />
        ) : null}
        {props.children}
      </Container>
    </OuterContainer>
  )
}

Modal.defaultProps = {
  withExitButton: true,
  onExitPress: () => {},
  mobileOnly: false,
}

interface ModalProps {
  open: boolean;
  withExitButton?: boolean;
  onExitPress?: () => void;
  mobileOnly?: boolean;
}

interface OuterContainerProps {
  open: boolean;
  mobileOnly: boolean;
  blur: boolean;
}

const OuterContainer = styled.div<OuterContainerProps>`
  ${(props) => (props.mobileOnly ? tw`inline-block lg:hidden` : null)}
  ${(props) => (props.blur ? tw`bg-gray-900 bg-opacity-75` : null)}
  ${tw`md:px-3`};
  ${tw`absolute top-0 w-full h-screen items-center flex`};
  backdrop-filter: blur(3px) grayscale(100);
  ${tw`transition-opacity duration-200 ease-in-out`};
  ${(props) => (props.open ? tw`opacity-100` : tw`opacity-0`)};
`
const Container = styled.div<{ open: boolean }>`
  ${tw`flex flex-col`}
  ${tw`shadow-xl md:rounded m-6 relative m-auto`};
  ${tw`transition-all duration-200 ease-in-out`}
  ${(props) =>
    props.open
      ? tw`transform scale-100 opacity-100`
      : tw`transform scale-50 opacity-0`};
`
const ExitButton = tw(
  FontAwesomeIcon,
)`absolute right-0 top-0 m-4 text-xl hover:cursor-pointer`

export default Modal

import React from 'react'
import tw from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactInfo: React.FC<Props> = (props) => {
  return (
    <Container>
      <InnerContainer>
        <Phone href={`tel:+1${props.phone.replace(/-/g, '')}`}>
          <StyledFontAwesome icon={['fas', 'phone']} size="lg" /> {props.phone}
        </Phone>
      </InnerContainer>
    </Container>
  )
}

const Container = tw.div`inline-block`
const InnerContainer = tw.div`text-sm xl:text-base`
const StyledFontAwesome = tw(
  FontAwesomeIcon,
)`h-5 xl:h-5 w-5 mr-2 text-blue-600`
const Phone = tw.a`inline-block px-4`

interface Props {
  address: string;
  phone: string;
}

export default ContactInfo

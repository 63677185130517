import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import PhoneFormat from '../lib/PhoneFormat'

const MobileMenu: React.FC<Props> = (props) => {
  const serviceMenu = props.services.map((service, key) => {
    const serviceUrl = `/services/${service.serviceName
      .replace(' ', '-')
      .toLowerCase()}`
    return (
      <MenuListItem key={key}>
        <MenuItem
          to={serviceUrl}
          onClick={props.onNavigate}
          role="menuitem"
          tabIndex={-1}
        >
          {service.serviceName}
        </MenuItem>
      </MenuListItem>
    )
  })

  const phoneNumber = new PhoneFormat(props.phone).toString()

  return (
    <Container>
      <MenuList role="menu" id="mobile-menu">
        <MenuListItem>
          <MenuHeader>
            <Link to="/" onClick={props.onNavigate}>
              Home
            </Link>
          </MenuHeader>
        </MenuListItem>
        <MenuListItem>
          <MenuHeader>Services</MenuHeader>
        </MenuListItem>
        {serviceMenu}
        <MenuListItem>
          <MenuHeader>Contact</MenuHeader>
        </MenuListItem>
        <MenuListItem>
          <MenuItemAnchor
            href={`tel:+1${props.phone}`}
            role="menuitem"
            tabIndex={-1}
          >
            {phoneNumber}
          </MenuItemAnchor>
        </MenuListItem>
        <MenuListItem>
          <MenuItemAnchor
            href={`mailto:${props.email}`}
            role="menuitem"
            tabIndex={-1}
          >
            {props.email}
          </MenuItemAnchor>
        </MenuListItem>
      </MenuList>
    </Container>
  )
}

MobileMenu.defaultProps = {
  phone: '',
  email: '',
  services: [],
  onNavigate: () => {},
}

const Container = tw.div`h-screen w-screen text-left bg-opacity-75 bg-white pt-32`
const MenuList = tw.ul`w-full px-6`
const MenuListItem = tw.li`w-full`
const MenuHeader = tw.h3`text-2xl font-bold pl-2 py-3`
const MenuItem = tw(
  Link,
)`text-lg block w-full tracking-wide focus:outline-none pl-8 py-3`
const MenuItemAnchor = tw.a`text-lg block w-full tracking-wide focus:outline-none pl-8 py-3`

interface Props {
  services?: {
    serviceName: string;
  }[];
  phone?: string;
  email?: string;
  onNavigate?: () => void;
  toggle?: Function;
}

export default MobileMenu

import React from 'react'
import tw from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledFontAwesome = tw(FontAwesomeIcon)`h-6 w-6`

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      copyright: contentfulTextCopy(key: { eq: "Copyright" }) {
        value
      }
      allContentfulSocialMedia {
        edges {
          node {
            name
            url
            icon
          }
        }
      }
    }
  `)

  const socialMediaLinks = data.allContentfulSocialMedia.edges.map(
    (sm, key) => {
      return (
        <SocialMediaLink href={sm.node.url} target="_blank" key={key}>
          <SocialMediaName>{sm.node.name}</SocialMediaName>
          <StyledFontAwesome icon={['fab', sm.node.icon]} size="2x" />
        </SocialMediaLink>
      )
    },
  )

  if (data.allContentfulSocialMedia.edges.length === 0) {
    return null
  }

  return (
    <Container>
      <InnerContainer>
        <SocialMediaContainer>{socialMediaLinks}</SocialMediaContainer>
        <CopyrightContainer>
          {data.copyright?.value && <Copyright>{data.copyright.value}</Copyright>}
        </CopyrightContainer>
      </InnerContainer>
    </Container>
  )
}

const Container = tw.footer`bg-gray-100`
const InnerContainer = tw.div`max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8`
const SocialMediaContainer = tw.div`mt-8 flex justify-center`
const SocialMediaLink = tw.a`text-blue-600 hover:text-blue-400 m-4`
const SocialMediaName = tw.span`sr-only`
const CopyrightContainer = tw.div`mt-8`
const Copyright = tw.p`text-center text-base leading-6 text-blue-600`

export default Footer

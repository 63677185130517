import React from 'react'
import tw from 'twin.macro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useStaticQuery, graphql } from 'gatsby'
import contentfulRichText from '../lib/contentfulRichText'
import { useDebouncedCallback } from 'use-debounce'
import generateId from '../lib/generateId'
import PhoneFormat from '../lib/PhoneFormat'
import runFunction from '../lib/runFunction'

const validationSchema = Yup.object({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email().required('Email address is required'),
  phone: Yup.string().required('Phone Number is required'),
  message: Yup.string().required('A Message is required'),
})

interface CopyData {
  howCanWeHelp: {
    value: string
  }
  submitCopy: {
    value: string
  }
  confirmation: {
    content: {
      raw: string
    }
  }
}

const ContactForm: React.FC = () => {
  const [uniqueId, setUniqueId] = React.useState<string>(null)

  const debouncedSubmit = useDebouncedCallback(async (values) => {
    const uniqueId = generateId(8)
    setUniqueId(uniqueId)
    const token = await window['runRecaptcha']()
    await runFunction('sendmail', { ...values, token, uniqueId })
    formik.resetForm()
  }, 500)

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      message: '',
    },
    onSubmit: async (values) => {
      await debouncedSubmit.callback(values)
    },
    validationSchema,
  })

  const handlePhoneChange = (e) => {
    const formatter = new PhoneFormat(formik.values.phone)
    if (e.keyCode >= 48 || e.keyCode <= 57 || e.keyCode === 8) {
      if (e.keyCode === 8) {
        formatter.backspace()
      } else {
        formatter.addNumber(e.key)
      }
      formik.setFieldValue('phone', formatter.toString())
    }
  }

  const data = useStaticQuery<CopyData>(graphql`
    query {
      confirmation: contentfulWysiwyg(
        contentTitle: { eq: "Confirmation Message" }
      ) {
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              fluid(maxWidth: 2500, quality: 60) {
                aspectRatio
                src
                srcSet
                sizes
              }
              fixed(width: 1600) {
                width
                height
                src
                srcSet
              }
            }
          }
        }
      }
      howCanWeHelp: contentfulTextCopy(key: { eq: "How can we help?" }) {
        value
      }
      submitCopy: contentfulTextCopy(key: { eq: "Submit Contact Form" }) {
        value
      }
    }
  `)

  let confirmation = null
  if (data.confirmation) {
    confirmation = contentfulRichText(JSON.parse(data.confirmation.content.raw), data.confirmation.content.references)
  }

  return (
    <Container>
      <InnerContainer>
        {uniqueId && (
          <div tw="text-center">
            <div tw="p-2">{confirmation}</div>
            <div tw="p-2">
              <p>
                Your confirmation code: <b>{uniqueId}</b>
              </p>
            </div>
          </div>
        )}
        {!uniqueId && (
          <Form onSubmit={formik.handleSubmit}>
            <div>
              <label htmlFor="fullname" className="sr-only">
                Full Name
              </label>
              <FieldContainer>
                <InputField
                  id="fullname"
                  placeholder="Full Name"
                  name="fullName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  role="textbox"
                />
              </FieldContainer>
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <FieldContainer>
                <InputField
                  placeholder="Email"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  role="textbox"
                />
              </FieldContainer>
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Phone Number
              </label>
              <FieldContainer>
                <InputField
                  placeholder="Phone Number"
                  id="phone"
                  name="phone"
                  type="tel"
                  onKeyDown={handlePhoneChange}
                  onChange={() => null}
                  value={formik.values.phone}
                  role="textbox"
                />
              </FieldContainer>
            </div>
            <div>
              <label htmlFor="message" className="sr-only">
                {data.howCanWeHelp.value}
              </label>
              <FieldContainer>
                <TextareaField
                  placeholder={data.howCanWeHelp.value}
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  role="textbox"
                />
              </FieldContainer>
            </div>
            <div>
              <SubmitContainer>
                <SubmitButton
                  role="button"
                  type="submit"
                  data-action="submit"
                  disabled={
                    !formik.dirty || !formik.isValid || formik.isSubmitting
                  }
                >
                  {data.submitCopy.value}
                </SubmitButton>
              </SubmitContainer>
            </div>
          </Form>
        )}
      </InnerContainer>
    </Container>
  )
}

const Container = tw.div`bg-transparent`
const InnerContainer = tw.div`max-w-lg mx-auto lg:max-w-none`
const FieldContainer = tw.div`relative rounded-md shadow-md`
const Form = tw.form`grid grid-cols-1 row-gap-6`
const InputField = tw.input`block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-300 focus:outline-none`
const TextareaField = tw.textarea`block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-300`
const SubmitContainer = tw.span`inline-flex rounded-md shadow-md`
const SubmitButton = tw.button`disabled:bg-gray-800 disabled:hover:(bg-gray-800 cursor-not-allowed) inline-flex text-white bg-blue-700 border-0 py-2 px-6 focus:outline-none hover:bg-blue-400 rounded-sm text-lg shadow-xl transition-transform transform duration-100 active:scale-95 hover:cursor-pointer`

export default ContactForm

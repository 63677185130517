import axios, { AxiosResponse } from 'axios'

const config = {
  returnRejectedPromiseOnError: true,
  timeout: 10000,
  baseURL: '/.netlify/functions',
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
}

async function runFunction<T>(
  fnName: string,
  requestData = {},
): Promise<AxiosResponse<T>> {
  const instance = axios.create(config)

  instance.interceptors.request.use(
    function (config) {
      console.log(config.data)
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  return instance.post(fnName, requestData, { params: requestData })
}

export default runFunction

import React from 'react'
import tw from 'twin.macro'

const SetWidthSection: React.FC = (props) => {
  return (
    <Section>
      <SectionContent>{props.children}</SectionContent>
    </Section>
  )
}

const Section = tw.section`relative pt-6 pb-12 lg:(pt-12 pb-24)`
const SectionContent = tw.div`container mx-auto`

export default SetWidthSection

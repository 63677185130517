import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image/withIEPolyfill'

export default (richText, refs = null) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="is-prose">{children}</li>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const asset = refs.find((ref) => {
          return ref.contentful_id === node.data.target.sys.id
        })

        if (asset && asset.fluid) {
          return (
            <div style={{marginBottom: '100px'}}>
              <Img fluid={asset.fluid} style={{overflow: 'visible'}} />
            </div>
          )
        }

        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        console.error('You\'ve attempted to use an embedded entry when they are disabled.')
        return null
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} rel="noopener noreferrer" target="_blank">
            {children}
          </a>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        console.error('You\'ve attempted to use an entry hyperlink when they are disabled.')
        return null
      },
    },
  }

  return documentToReactComponents(richText, options)
}

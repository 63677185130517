class PhoneFormat {
  static PHONE_NUMBER_LENGTH = 10

  static isValidInput(number: string): boolean {
    const matches = number.match(/[0-9]/g)
    return matches !== null && matches.length > 0
  }

  static filterString(input: string): string {
    return input.replace(/[^0-9]/g, '')
  }

  private currentValue = ''

  constructor(initialValue: string) {
    this.currentValue = PhoneFormat.filterString(initialValue).substring(
      0,
      PhoneFormat.PHONE_NUMBER_LENGTH,
    )
  }

  addNumber(number: string): void {
    if (this.currentValue.length < 10 && PhoneFormat.isValidInput(number)) {
      this.currentValue = this.currentValue.concat(number)
    }
  }

  backspace(): void {
    if (this.currentValue.length > 0) {
      this.currentValue = this.currentValue.slice(0, -1)
    }
  }

  toString(): string {
    return this.currentValue.split('').reduce((phoneNumber, char, index) => {
      if (index === 0) {
        return phoneNumber.concat(`(${char}`)
      } else if (index === 3) {
        return phoneNumber.concat(`) ${char}`)
      } else if (index === 6) {
        return phoneNumber.concat(`-${char}`)
      }
      return phoneNumber.concat(char)
    }, '')
  }
}

export default PhoneFormat

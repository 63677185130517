import React from 'react'
import tw, { styled } from 'twin.macro'
import classnames from 'classnames'

const Hamburger: React.FC<Props> = (props) => {
  const classes = classnames('hamburger', 'hamburger--squeeze', {
    'is-active': props.active,
  })

  return (
    <Button
      className={classes}
      onClick={props.onClick}
      type="button"
      aria-label="Menu"
      aria-haspopup="true"
      aria-controls="mobile-menu"
      aria-expanded={props.active}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </Button>
  )
}

Hamburger.defaultProps = {
  active: false,
  onClick: () => {},
}

interface Props {
  active?: boolean;
  onClick?: () => void;
}

const Button = styled.button`
  ${tw`p-0 mr-3 focus:outline-none transform scale-90`};
`

export default Hamburger

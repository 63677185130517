import React from 'react'
import ReactDOM from 'react-dom'
import Modal from '../components/Modal'

function useModal<T extends React.ComponentType<{ toggle: () => unknown }>>(
  Component: T,
  props: React.ComponentProps<T> = {} as React.ComponentProps<T>,
  modalProps: ModalProps = {},
): ModalInterface {
  const [open, setOpen] = React.useState<boolean>(false)

  const toggle = () => {
    setOpen((isOpen) => {
      return !isOpen
    })
  }

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }

    let element = document.getElementById('modal')
    if (!element) {
      element = document.createElement('div')
      element.setAttribute('id', 'modal')
      document.body.appendChild(element)
    }

    const component = React.createElement(Component, { ...props, toggle })
    ReactDOM.render(
      <Modal onExitPress={handleExit} open={open} {...modalProps}>
        {component}
      </Modal>,
      element,
    )

    if (!open) {
      setTimeout(() => {
        element.remove()
      }, 100)
    }
  }, [open])

  const handleExit = () => {
    setOpen(false)
  }

  return {
    toggle,
    isOpen: open,
  }
}

interface ModalProps {
  withExitButton?: boolean;
  mobileOnly?: boolean;
}

interface ModalInterface {
  toggle: () => void;
  isOpen: boolean;
}

export default useModal

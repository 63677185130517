import React from 'react'
import Helmet from 'react-helmet'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import ContactButton from '../components/ContactButton'
import Hamburger from '../components/Hamburger'
import Logo from '../components/Logo'
import MobileMenu from '../components/MobileMenu'
import useModal from '../hooks/useModal'
import ContactInfo from './sections/ContactInfo'
import Footer from './sections/Footer'
import Menu from './sections/Menu'

const GlobalStyle = createGlobalStyle`
  li.is-prose p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  li.is-prose {
    padding: 0 !important;
    padding-left: 0 !important;
    margin: 0 !important;
    margin-left: 1.8em !important;
  }
`

const MainLayout: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      services: allContentfulService {
        edges {
          node {
            serviceName
          }
        }
      }
      siteTitle: contentfulSetting(key: { eq: "title" }) {
        value
      }
      metaTags: allContentfulMetaTags {
        edges {
          node {
            name
            content
            enabled
          }
        }
      }
    }
  `)

  const services = data.services.edges.map(({ node }) => node)
  const { toggle, isOpen } = useModal(
    MobileMenu,
    {
      services,
      phone: props.phone,
      email: '',
      onNavigate: () => {
        toggle()
      },
    },
    {
      withExitButton: false,
      mobileOnly: true,
    },
  )

  const metaTags = data.metaTags?.edges?.reduce((metaTags, { node }) => {
    if (node.enabled) {
      metaTags.push(<meta name={node.name} property={node.name} content={node.content} key={node.name} />)
    }
    return metaTags
  }, [])

  return (
    <Container>
      <Helmet>
        <html lang="en" />
        <title>{data.siteTitle ? data.siteTitle.value : 'NC Accounting'}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="robots" content="index,follow" />
        {metaTags}
      </Helmet>
      <GlobalStyle />
      <HeaderContainer>
        <HeaderBar>
          <Logo />
          <HamburgerContainer>
            <Hamburger onClick={toggle} active={isOpen} />
          </HamburgerContainer>
          <ContactLinks>
            <ContactInfo address={props.address} phone={props.phone} />
            <Menu services={services} />
            <ContactButton />
          </ContactLinks>
        </HeaderBar>
      </HeaderContainer>
      <Main fixed={props.fixed}>{props.children}</Main>
      <Footer />
    </Container>
  )
}

MainLayout.defaultProps = {
  fixed: false,
  phone: '',
  address: '',
}

interface Props {
  fixed?: boolean;
  phone?: string;
  address?: string;
}

const Container = styled.div`
  max-width: 2500px;
  ${tw`m-auto`};
`
const Main = styled.main`
  ${tw`flex flex-grow flex-col`};
  ${(props) => (props.fixed ? null : tw`mt-32 lg:mt-64`)};
`
const HeaderContainer = tw.header`absolute w-full top-0 z-10`
const HeaderBar = tw.div`flex items-center justify-between my-6 container mx-auto px-4`
const ContactLinks = tw.div`hidden md:flex flex-shrink-0 items-center`
const HamburgerContainer = tw.div`flex md:hidden z-50`

export default MainLayout

import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Logo: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulImage(key: { eq: "Color Logo" }) {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledAnchorLink to="/">
      <LogoGraphic
        fluid={data.contentfulImage.image.localFile.childImageSharp.fluid}
        alt="NCAccounting Logo"
      />
    </StyledAnchorLink>
  )
}

const StyledAnchorLink = tw(
  AnchorLink,
)`block text-left ml-3 lg:(ml-0 inline-block mx-0 text-center)`
const LogoGraphic = styled(Img)`
  width: 200px;
  ${tw`block mx-auto xl:w-250`};
`

export default Logo

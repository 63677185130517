import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'

const Menu: React.FC<Props> = (props) => {
  const serviceList = props.services.map((service, index) => {
    const serviceUrl = `/services/${service.serviceName
      .replace(' ', '-')
      .toLowerCase()}`
    return (
      <Service key={index}>
        <Link to={serviceUrl}>
          {service.shortname ? service.shortname : service.serviceName}
        </Link>
      </Service>
    )
  })

  return (
    <Container>
      <InnerContainer>{serviceList}</InnerContainer>
    </Container>
  )
}

const Container = tw.nav`inline-block`
const InnerContainer = tw.div`text-sm xl:text-base`
const Service = tw.div`inline-block px-4 border-l-2 border-blue-600`

interface Props {
  services: {
    serviceName: string;
    shortname: string;
  }[];
}

export default Menu

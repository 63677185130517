import React from 'react'
import tw from 'twin.macro'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const ContactButton: React.FC = () => {
  return <StyledAnchorLink to="/#contact">Contact Us Today!</StyledAnchorLink>
}

const StyledAnchorLink = tw(
  AnchorLink,
)`inline-flex text-white bg-blue-700 border-0 py-2 px-6 focus:outline-none hover:bg-blue-400 rounded-sm text-base shadow-xl transition-transform transform duration-100 active:scale-95 hover:cursor-pointer`

export default ContactButton
